import "../sass/project.scss";

document.addEventListener("DOMContentLoaded", function () {
  let stripe;
  let formData = {};

  // Function to get CSRF token from cookies
  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }

  // Get CSRF token
  const csrftoken = getCookie('csrftoken');

  // Initialize Stripe
  if (window.Stripe) {
    stripe = Stripe(
      "pk_live_51P9aPJ06EFtfNejMcuRviCikQHWF7f4l0x4ZLG1VBM1J2AqUFRBmUfWH0cxEQWir5jqUeIoBRcNRtRA19xqf4y9M00YwOV16ZG"
    );
    console.log("Stripe initialized:", stripe);
  } else {
    console.error("Stripe.js not loaded");
  }

  // Mobile menu functionality
  const mobileMenuButton = document.querySelector(".mobile-menu-button");
  const mobileMenuClose = document.querySelector(".mobile-menu-close");
  const mobileMenu = document.getElementById("mobile-menu");

  function toggleMobileMenu() {
    const expanded = mobileMenuButton.getAttribute("aria-expanded") === "true";
    mobileMenuButton.setAttribute("aria-expanded", !expanded);
    mobileMenu.classList.toggle("hidden");
  }

  if (mobileMenuButton && mobileMenuClose && mobileMenu) {
    mobileMenuButton.addEventListener("click", toggleMobileMenu);
    mobileMenuClose.addEventListener("click", toggleMobileMenu);
  }

  // Signup functionality
  const initialSignupForm = document.getElementById("initial-signup-form");
  const signupForm = document.getElementById("signup-form");
  const processingModal = document.getElementById("processing-modal");

  function showProcessingModal() {
    if (processingModal) processingModal.classList.remove("hidden");
  }

  function hideProcessingModal() {
    if (processingModal) processingModal.classList.add("hidden");
  }

  if (initialSignupForm) {
    initialSignupForm.addEventListener("submit", function (e) {
      e.preventDefault();
      if (validateInitialStep()) {
        initialSignupForm.style.display = "none";
        signupForm.style.display = "block";
      }
    });
  }

  function validateInitialStep() {
    const inputs = initialSignupForm.querySelectorAll("input[required]");
    let isValid = true;

    inputs.forEach((input) => {
      if (!input.value.trim()) {
        isValid = false;
        input.classList.add("border-red-500");
      } else {
        input.classList.remove("border-red-500");
      }
    });

    if (!isValid) {
      alert("Please fill in all required fields.");
    }

    async function checkEmailAvailability() {
      const email = document.getElementById("email").value;
      showProcessingModal();

      try {
        const response = await fetch("/accounts/check_email/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrftoken,
          },
          body: JSON.stringify({ email: email }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (data.available) {
          // Email is available, proceed to next step
          initialSignupForm.style.display = "none";
          signupForm.style.display = "block";
        } else {
          // Email is already in use
          showEmailExistsMessage(email);
        }
      } catch (error) {
        handleError(error, "Error checking email");
      } finally {
        hideProcessingModal();
      }
    }

    function showEmailExistsMessage(email) {
      const messageContainer = document.createElement("div");
      messageContainer.className = "alert alert-warning";
      messageContainer.innerHTML = `
      <p>An account with the email ${email} already exists.</p>
      <p>Would you like to <a href="${VUE_APP_URL}/login" class="font-bold">log in</a> instead?</p>
    `;

      const formContainer = initialSignupForm.parentElement;
      formContainer.insertBefore(messageContainer, initialSignupForm);

      // Optionally, scroll to the message
      messageContainer.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    const password = document.getElementById("password").value;
    const password2 = document.getElementById("password2").value;
    if (password !== password2) {
      alert("Passwords do not match.");
      isValid = false;
    }

    return isValid;
  }

  if (signupForm) {
    const prevButton = signupForm.querySelector(".prev-step");
    const submitButton = signupForm.querySelector("#checkout-button");

    // Input formatting
    const phoneInput = signupForm.querySelector("#phone");
    const stateInputs = signupForm.querySelectorAll("#state, #license_state");
    const zipInput = signupForm.querySelector("#postal_code");

    if (phoneInput) {
      phoneInput.addEventListener("input", function (e) {
        e.target.value = formatPhoneNumber(e.target.value, 'parentheses');
      });
    }

    stateInputs.forEach((input) => {
      input.addEventListener("input", function (e) {
        e.target.value = e.target.value.toUpperCase().slice(0, 2);
      });
    });

    if (zipInput) {
      zipInput.addEventListener("input", function (e) {
        e.target.value = e.target.value.replace(/\D/g, "").slice(0, 9);
        if (e.target.value.length > 5) {
          e.target.value =
            e.target.value.slice(0, 5) + "-" + e.target.value.slice(5);
        }
      });
    }

    if (prevButton) {
      prevButton.addEventListener("click", function () {
        signupForm.style.display = "none";
        initialSignupForm.style.display = "block";
      });
    }

    if (submitButton) {
      submitButton.addEventListener("click", function (e) {
        e.preventDefault();
        if (validateStep()) {
          submitForm();
        }
      });
    }

    function validateStep() {
      const inputs = signupForm.querySelectorAll("input[required]");
      let isValid = true;

      inputs.forEach((input) => {
        if (!input.value.trim()) {
          isValid = false;
          input.classList.add("border-red-500");
        } else {
          input.classList.remove("border-red-500");
        }
      });

      if (!isValid) {
        alert("Please fill in all required fields.");
      }

      return isValid;
    }

    async function submitForm() {
      if (!stripe) {
        handleError(
          new Error("Stripe not initialized"),
          "Payment system is not ready"
        );
        return;
      }

      updateFormData();
      showProcessingModal();

      try {
        // Initial signup
        let response = await fetch(signupForm.action, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrftoken,
          },
          body: JSON.stringify({
            step: "initial",
            ...formData,
          }),
        });

        let data = await response.json();
        if (!data.success) {
          throw new Error(data.error || "Error in initial signup");
        }

        // Business info
        response = await fetch(signupForm.action, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrftoken,
          },
          body: JSON.stringify({
            step: "business",
            ...formData,
          }),
        });

        data = await response.json();
        if (!data.success) {
          throw new Error(data.error || "Error in business info submission");
        }

        // Payment
        response = await fetch(signupForm.action, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrftoken,
          },
          body: JSON.stringify({
            step: "payment",
            ...formData,
          }),
        });

        data = await response.json();
        if (!data.sessionId) {
          throw new Error("Error creating Stripe session");
        }

        const result = await stripe.redirectToCheckout({
          sessionId: data.sessionId,
        });

        if (result.error) {
          throw new Error(result.error.message);
        }
      } catch (error) {
        handleError(error, "Error during form submission");
      }
    }

    function updateFormData() {
      const allInputs = [
        ...initialSignupForm.querySelectorAll("input"),
        ...signupForm.querySelectorAll("input"),
      ];
      allInputs.forEach((input) => {
        formData[input.name] = input.value;
      });
    }
  }

  // User type selection handling
  const userTypeSelect = document.getElementById('user_type');
  const agentFields = document.querySelectorAll('.agent-field');
  const providerFields = document.querySelectorAll('.provider-field');

  userTypeSelect.addEventListener('change', function() {
    const selectedValue = this.value;
    
    if (selectedValue === 'agent') {
      agentFields.forEach(field => field.style.display = 'block');
      providerFields.forEach(field => field.style.display = 'none');
      document.getElementById('npn').required = true;
      document.getElementById('employee_id').required = false;
    } else if (selectedValue === 'facility') {
      agentFields.forEach(field => field.style.display = 'none');
      providerFields.forEach(field => field.style.display = 'block');
      document.getElementById('npn').required = false;
      document.getElementById('employee_id').required = true;
    } else {
      agentFields.forEach(field => field.style.display = 'none');
      providerFields.forEach(field => field.style.display = 'none');
      document.getElementById('npn').required = false;
      document.getElementById('employee_id').required = false;
    }
  });

  // Check for successful signup on page load
  const urlParams = new URLSearchParams(window.location.search);
  const sessionId = urlParams.get("session_id");
  if (sessionId) {
    showProcessingModal();
    if (processingModal) {
      processingModal.querySelector("#processing-content").innerHTML =
        "<h3>Finalizing your account</h3><p>Please wait while we complete your registration...</p>";
    }

    // Poll for account activation
    const pollInterval = setInterval(async function () {
      try {
        const response = await fetch(
          `/accounts/signup/success/?session_id=${sessionId}`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        if (data.status === "completed") {
          clearInterval(pollInterval);
          hideProcessingModal();
          alert(
            "Your account has been successfully created. You can now log in."
          );
          window.location.href = "{{ VUE_APP_URL }}/login";
        }
      } catch (error) {
        handleError(error, "Error checking signup status");
        clearInterval(pollInterval);
      }
    }, 2000); // Check every 2 seconds

    // Set a timeout to stop polling after 2 minutes
    setTimeout(() => {
      clearInterval(pollInterval);
      hideProcessingModal();
      alert(
        "Account activation is taking longer than expected. Please contact support if you encounter any issues logging in."
      );
    }, 120000);
  }

  // Add this function at the beginning of your file
  function handleError(error, customMessage = "An error occurred") {
    console.error(error);
    hideProcessingModal();
    alert(`${customMessage}: ${error.message}`);
  }

  // Add this function near the top with other utility functions
  function formatPhoneNumber(value, style = 'parentheses') {
    // Strip out all non-digits
    let digits = value.replace(/\D/g, "");
    
    let formatted = "";
    if (style === 'parentheses') {
      // Format as (123) 456-7890
      let x = digits.match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      formatted = !x[2] ? x[1] : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    } else {
      // Format as 123-456-7890
      if (digits.length <= 3) {
        formatted = digits;
      } else if (digits.length <= 6) {
        formatted = digits.slice(0, 3) + "-" + digits.slice(3);
      } else {
        formatted = digits.slice(0, 3) + "-" + digits.slice(3, 6) + "-" + digits.slice(6, 10);
      }
    }
    return formatted;
  }

  // Add the new contact form phone formatting
  console.log("Contact form JS loaded");
  
  const contactForm = document.getElementById("contact-form");
  console.log("Contact form found?", !!contactForm);
  
  if (contactForm) {
    const contactPhoneInput = document.getElementById("id_phone_number");
    console.log("Phone input found?", !!contactPhoneInput);
    
    if (contactPhoneInput) {
      contactPhoneInput.addEventListener("input", function (e) {
        e.target.value = formatPhoneNumber(e.target.value, 'dashes');
      });
    }
  }
});
